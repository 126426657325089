<template>
  <div class="home">
    <top-nav @menu_data="getMenuData" :isARClogo="isARClogo" />
    <div class="part1">
      <img src="./../../assets/imgs/app/arcaniteConnect/p0.png" class="part1Pic" />
      <div class="part1Title">
        Arcanite Connect
      </div>
      <div class="part1Content">
        Arcanite's mobile-friendly admin dashboard was designed for business managers on the go. Stay in control of your company's workflows, teams, projects and sales from any location with Arcanite Connect. 
      </div>
      <div style="margin:auto">
        <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true');
      return false;">
          <div class="part1Button">
            <div class="part1Text">Book A Demo</div><img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
          </div>
        </a>
        <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap');
      return false;">
          <div class="part1Button">
            <div class="part1Text">Book A Demo</div><img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
          </div>
        </a>
      </div>
    </div>
     <div class="part2RecoginizedIcons">
      <div class="part2-topcontent">
        <trusted></trusted>
      </div>
      <div class="part2-midcontent">
        <featuredIn></featuredIn>
      </div>
      <div class="part2-bottomcontent">
          <recognised></recognised>
      </div>
    </div>
    <div v-for="i,index in pageContent" :key="'part2'+index">
      <div class="part2">
        <div>
          <img :src="i.bottomPic" :style="i.bottomPicSize" />
        </div>
        <div :style="i.backStyle">
        <img :src="i.minicon" class="pic" />
        <div class="part2Title">
          {{i.title}}
        </div>
        <div class="part2Content">
          {{i.content}}
        </div>
        <div v-if="i.subImg" style="display:flex">
          <img :src="j" class="subImg" v-for="j,indexj in i.subImg" :key="'img'+indexj">
        </div>
        <div v-if="i.mini">
          <img :src="j" class="mini" v-for="j,indexj in i.mini" :key="'mini'+indexj">
        </div>
        <div v-if='i.subLine' class="part2Line"></div>
        <div class="part2subLine" v-for="j,indexj in i.subLine" :key="'subline'+indexj">
          <div class="dog"></div>
          <div class="text">{{j}}</div>
        </div>
        </div>
      </div>
    </div>
    <div class="part5">
      <div class="title">
        Join the thousands of real estate professionals using Arcanite to streamline their teams everyday project marketing processes.
      </div>
      <div style="margin:auto;">
        <a v-if="!isID" href=" " onclick="Calendly.showPopupWidget('https://meetings.hubspot.com/william463?embed=true'); return false;">
          <div class="button">
            Book A Demo<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
          </div>
        </a>
        <a v-else href=" " onclick="Calendly.showPopupWidget('https://calendly.com/erni-yap'); return false;">
          <div class="button">
            Book A Demo<img src='./../../assets/imgs/app/arcanite/vector.png' class="vector">
          </div>
        </a>
      </div>
    </div>
    <other-product></other-product>
    <footer-nav />
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
// import OtherProduct from '../PC/OtherProduct.vue';
export default {
  data() {
    return {
      isID:false,
      showMenuFlag: "0",
      isShow: false,
      isARClogo: true,
      part5MiddleNumber: 2,
      // part5LeftNumber:1,
      // part5RightNumber:3,
      part5All: 3,
      pageContent: [
        {
          title: 'View agent and project statistics',
          minicon: require('@/assets/imgs/app/arcaniteConnect/minicon1.png'),
          content: 'Track your agents’ activity on the Arcanite platform and which projects are performing best.',
          subLine: ['Identify the amount of interest each project is receiving', 'Track agent logins and activity', 'View expressions of interest, new contracts and sales exchanged'],
          bottomPic: require('@/assets/imgs/app/arcaniteConnect/p1.png'),
          bottomPicSize: { width: '100%', marginTop: '73px' },
          backStyle:{marginTop:'-50px'}
        },
        {
          title: 'Approve requests in the app',
          minicon: require('@/assets/imgs/app/arcaniteConnect/minicon2.png'),
          content: 'Be notified of agent requests directly through Arcanite Connect. Review requests and approve instantly. ',
          subLine: ['Speed up processes by eliminating response wait time', 'Approve reservations and cancellations', 'Update sales and confirm deposits'],
          bottomPic: require('@/assets/imgs/app/arcaniteConnect/p2.png'),
          bottomPicSize: { width: '100%' },
          backStyle:{marginTop:'-30px'}
        },
        {
          title: 'Keep your team updated',
          minicon: require('@/assets/imgs/app/arcaniteConnect/minicon3.png'),
          content: 'When changes to a project occur, use Arcanite Connect to instantly update new details on your agents Arcanite app.',
          subLine: ['Adjust property prices and availability', 'Amend sales statuses as changes occur', 'Keep Arcanite app’s information up to date for your agents'],
          bottomPic: require('@/assets/imgs/app/arcaniteConnect/p3.png'),
          bottomPicSize: { width: '96%',marginLeft:'10px' }
        },
        {
          title: 'Communicate with your team',
          minicon: require('@/assets/imgs/app/arcaniteConnect/minicon4.png'),
          content: 'Send instant notifications to your entire team or selected members from Arcanite Connect to their Arcanite app.',
          subLine: ['Schedule when notifications are to be sent', 'Schedule when notifications are to be sent', 'Select to notify the entire team or selected members only'],
          bottomPic: require('@/assets/imgs/app/arcaniteConnect/p4.png'),
          bottomPicSize: { width: '100%'}
        }]
    };
  },
  created() {
    if(localStorage.getItem('country')=="id"){
      this.isID=true;
    }else{
      this.isID=false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    // this.handleScroll();
    // this.handleFirstPara();
  },
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    },
    handleClickDisplay() {
      this.isMove = true;
    },
    changePic(p) {
      this.part5MiddleNumber = p
    },
  },

  components: {
    "top-nav": (resolve) => require(["./TopNav.vue"], resolve),
    "footer-nav": (resolve) => require(["./FooterNav.vue"], resolve),
    "menu-bar": (resolve) => require(["./MenuBar.vue"], resolve),
    "trusted": (resolve) => require(["./Trusted.vue"], resolve),
    "recognised": (resolve) => require(["./Recognised.vue"], resolve),
    // "more-features":(resolve) => require(["./MoreFeatures.vue"], resolve),
    "other-product": (resolve) => require(["./OtherProduct.vue"], resolve),
  },
};
</script>
<style lang="scss" scoped>
.home {
  text-align: center;
  display: block;
  width: 100%;
  margin: auto;
  max-width: 428px;
  min-width: 320px;
}

.part1 {
  // margin: auto;
  // width: 100vw;
  margin:0 26px 0 26px;
  overflow-x: hidden;
  // height: 659px;
  padding-top: 100px;
  // height: 100%;
  .part1Pic {
    margin: auto;
    width: 100%;
    // height: 402.8px;
  }
  .part1Title {
    margin: auto;
    margin-top: 15px;
    // width: 323px;
    height: 36px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 30px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  .part1Content {
    margin: auto;
    // width: 323px;
    // height: 120px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
    padding-top: 10px;
  }
  .part1Button {
    // display: flex;
    margin-top: 30px;
    width: 150px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    padding: 10px 16px;
    border-radius: 5px;
    background-color: #1890ff;
    // display: flex;
    height: 22px;
    margin-bottom: 55px;
  }
  .part1Text {
    width: 120px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.part2RecoginizedIcons{
  margin: 0px auto;
  // width: 1210px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items:center;
  gap: 30px;
}

.vector {
  width: 22px;
  height: 22px;
  flex-grow: 0;
  object-fit: contain;
  margin-left: 10px;
}

.part2 {
  // margin: auto;
  margin: 0 16px 0 16px;
  // width: 333px;
  height: auto;
  display: block;
  overflow-x: hidden;
  text-align: left;
  margin-bottom: 70px;
  .part2Title {
    margin: 10px;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 26px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  .part2Content {
    margin: 10px;
    // margin-left: 10px;
    // width: 323px;
    // height: 60px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
  }
  .part2Line {
    margin: auto;
    margin: 20px 10px 20px 10px;
    // width: 323px;
    height: 1px;
    background-color: #e6ebf2;
  }
  .subImg {
    width: 40px;
    height: 40px;
    flex-grow: 0;
    display: inline-block;
    // object-fit: contain;
    // border-radius: 50%;
  }
  .part2subLine {
    margin: auto;
    display: flex;
    margin: 0 10px 0 10px;
    .dog {
      margin: 4px 10px 28px 0;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      flex-grow: 0;
      background-color: #1890ff;
    }
    .text {
      // height: 18px;
      margin-bottom: 22px;
      flex-grow: 1;
      font-family: Poppins;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #314455;
    }
  }
}

.pic {
  width: 80px;
  height: 80px;
}

// .part5Back{
//     }
.part5 {
	margin: auto;
  width: 100%;
  overflow: hidden;
  // width: 375px;
  min-height: 311px;
  background-image: url("./../../assets/imgs/app/arcaniteConnect/p5.png");
  background-size: 100% 100%;
  .title {
    margin:30px 26px;
    // padding-top: 26px;
    // margin-bottom: 30px;
    // width: 325px;
    // height: 160px;
    align-self: stretch;
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 26px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .button {
    flex-grow: 0;
    font-family: Poppins-Bold;
    font-size: 16px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    // line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    width: 171px;
    height: 42px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // gap: 10px;
    border-radius: 5px;
    background-color: #062440;
    margin-left: 26px;
	margin-bottom: 26px;
  }
}
</style>

